<template>
   <b-form-group :label-for="page + '-' + name">
      <label class="p-0" v-if="require_form === false" :for="page + '-' + name">
         {{ label }}
      </label>
      <label v-if="require_form === true" for=""
         >{{ label }} <span class="p-0 text-danger h6">*</span></label
      >

      <v-select
         :id="page + '-' + name"
         :label="labelOptions"
         :options="options"
         :name="name"
         :placeholder="placeholder"
         :multiple="multiple"
         :value="modelValue"
         @input="
            (event) => {
               $emit('update:modelValue', event);
               validate;
            }
         "
      />

      <span class="text-sm text-red-500 font-ligth" v-if="error.path === name" > {{error.message}} </span>

   </b-form-group>
</template>

<script>
import {
   BFormGroup,
   BForm,
   BLink,
   BFormCheckbox,
   BFormInput,
   BInputGroup,
   BInputGroupAppend,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
export default {
   components: {
      vSelect,
      BFormGroup,
      BFormInput,
      BForm,
      BLink,
      BFormCheckbox,
      BInputGroup,
      BInputGroupAppend,
   },

   props: [
      'page',
      'label',
      'name',
      'placeholder',
      'validate',
      'error',
      'options',
      'require_form',
      'modelValue',
      'labelOptions',
      'multiple',
   ],

   setup() {},
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
