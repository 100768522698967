<template>
   <b-form-group :label-for="page + '-' + name">
      <label v-if="require_form === false" :for="page + '-' + name">
         {{ label }}
      </label>
      <label v-if="require_form === true" :for="page + '-' + name">
         {{ label }} <span class="p-0 text-danger h6">*</span></label
      >
      <flat-pickr
         class="form-control"
         :id="page + '-' + name"
         :name="name"
         value=""
         :placeholder="placeholder"
         :value="modelValue"
         @input="
            (event) => {
               $emit('update:modelValue', event);
               validate;
            }
         "
      />
      <span class="text-sm text-red-500 font-ligth" v-if="error.path === name" > {{error.message}} </span>

   </b-form-group>
</template>

<script>
import {
   BFormGroup,
   BForm,
   BLink,
   BFormCheckbox,
   BFormInput,
   BInputGroup,
   BInputGroupAppend,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
export default {
   components: {
      BFormGroup,
      BFormInput,
      BForm,
      BLink,
      BFormCheckbox,
      BInputGroup,
      BInputGroupAppend,
      flatPickr,
   },

   props: [
      'page',
      'label',
      'name',
      'placeholder',
      'validate',
      'error',
      'require_form',
      'modelValue',
   ],

   setup() {},
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
